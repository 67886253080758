import React from "react"
import {graphql} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Head from "../components/head"
import CallToAction from "../components/callToAction"
import {SharpImage} from "../utils/types"

interface Props {
  readonly data: PageQueryData
}

const cta = {
  id: "",
  URL: "https://calendly.com/tercen-demo/tercen-demo",
  text: "Book a training session",
}

const learningCta = {
  id: "",
  URL: "https://learn.tercen.com",
  text: "Tercen Learning Centre",
  bgColor: "bg-tercen-orange-base",
  bgHoverColor: "hover:bg-tercen-orange-hover",
}

const ThankYou: React.FC<Props> = ({data}) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <Head title="Thank You" keywords={[`data analysis`, `data analytics`, `data science`, `tercen`]} />
      <div className="container px-10 pb-6 text-black">
        <h3 className="text-5xl font-bold py-8">Thanks for registering with Tercen!</h3>
        <div className="">
          <GatsbyImage
            className="mx-auto w-64"
            image={data.laptop.childImageSharp.gatsbyImageData}
            alt="laptop image"
          />
        </div>
        <p className="text-2xl pt-4">The Tercen platform works best on desktop.</p>
        <p className="bg-tercen-yellow p-4 rounded-sm text-black leading-tight mt-6 font-black text-center">
          Please move to your desktop device to get the best experience from the Tercen platform.
        </p>
        <p className="pt-6">
          We want you to get the most out of your data. So, we'd like to offer you a{" "}
          <span className="font-semibold">free training session</span> with one of our technical staff to
          help you get started with your specific requirements.
        </p>
        <p className="pt-6">No sales tactics, just data. We promise! Just click an option below</p>
        <div className="pt-6">
          <CallToAction metadata={cta} />
        </div>
        <p className="pt-3 font-bold text-center">OR</p>
        <div className="pt-3">
          <CallToAction metadata={learningCta} />
        </div>
        <div className="pt-10 text-center">
          <GatsbyImage
            className="mx-auto w-48"
            image={data.thumbnail.childImageSharp.gatsbyImageData}
            alt="Author Image"
          />
          <p className="font-semibold pt-6 text-xl text-tercen-blue">Faris Naji</p>
          <p className="font-medium">Co-Founder & CEO</p>
          <GatsbyImage
            className="mx-auto mt-2 w-64"
            image={data.signature.childImageSharp.gatsbyImageData}
            alt="Author Image"
          />
        </div>
      </div>
    </Layout>
  )
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
  thumbnail: SharpImage
  signature: SharpImage
  laptop: SharpImage
}

export const pageQuery = graphql`
  query ThankYouQuery {
    site {
      siteMetadata {
        title
      }
    }
    thumbnail: file(relativePath: {eq: "MarionB.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 60)
      }
    }
    signature: file(relativePath: {eq: "Marion-Signature.png"}) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 60)
      }
    }
    laptop: file(relativePath: {eq: "Laptop.png"}) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 60)
      }
    }
  }
`
export default ThankYou
